<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-2">
    <h3>ID</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Label</h3>
  </div>
  <div layout="row" class="cell flex-1">
    <h3>Records</h3>
  </div>
  <div layout="row" class="cell flex-5">
    <h3>Solr Import Status</h3>
  </div>
  <div layout="row" class="cell flex-5">
    <h3></h3>
  </div>
  <div layout="row" class="cell flex-1">
    <h3>User</h3>
  </div>
</div>

<md-list class="list">
  <ng-template ngFor let-report [ngForOf]="reports.data">
    <div class="list-row" layout="row" [class.selected]="report.attributes.selected">
      <div layout="row" class="cell flex-2">
        <div>
          <span>{{ report.id }} : <b>{{ report.attributes.status }}</b></span>
          <em *ngIf="report.attributes.progress_status && report.attributes.status == 'running'">
            <br>
            {{ report.attributes.progress_status }}
          </em>
        </div>
      </div>
      <div layout="row" class="cell flex-2">
        <span>
          <b>{{ report.attributes.label }}</b>
          <br>
          <small>{{ report.attributes.created_at | date : 'short' }}</small>
        </span>
      </div>
      <div layout="row" class="cell flex-1">
        <small>{{ report.attributes.item_count }}</small>
      </div>
      <div layout="row" class="cell flex-5">
        <span>
          <small>{{ report.attributes.solr_progress_status }}</small>
        </span>
      </div>
      <div layout="row" class="cell flex-5">
        <span class="download-link" *ngIf="report.attributes.url">
          <a [href]="report.attributes.url" target="_blank">download</a><br>
        </span>
        <small class="error-message" *ngIf="report.attributes.error_message">{{ report.attributes.error_message }}</small>
      </div>
      <div layout="row" class="cell flex-1">
        <a *ngIf="report.attributes.user_id && report.attributes.user_id > 0" [routerLink]="['../users', report.attributes.user_id]">user</a>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
