<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <!-- <h2>{{user ? 'Edit ' + user.attributes.first_name : 'Invite New User' }} <span *ngIf="user.attributes.deleted_at">[Deleted Account]</span></h2> -->
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('first_name')">
      <h3>First Name</h3>
      <app-text-input [action]="action" field="first_name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('last_name')">
      <h3>Last Name</h3>
      <app-text-input [action]="action" field="last_name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('email')">
      <h3>email</h3>
      <app-text-input [action]="action" field="email" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('password')">
      <h3>password</h3>
      <app-text-input [action]="action" field="password" type="password" placeholder="" ></app-text-input>
    </div>

    <div *ngIf="action.field('password')">
      <h3>password again</h3>
      <app-text-input [action]="action" field="password_again" type="password" placeholder="" ></app-text-input>
    </div>

    <div class="password-match" *ngIf="action.form.value['password'] && action.form.value['password_again'] && action.form.value['password'] != action.form.value['password_again']"> The password and the password confirmation do not match  </div>
    <br>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>{{ 'Create' }}</span>
        </div>
      </button>
      <button *ngIf="deleteAction && !isCurrentUser" md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!deleteAction || isCurrentUser" (click)="deleteWarningDialog(user, 'Are you sure you want to delete this user?')">Delete</button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
