<div class="table-header" layout="row" hide show-gt-xs>
    <button md-button type="button" class="btn-add" (click)="addFeaturedCategoryDialog()">
      <md-icon>add</md-icon>
    </button>
    <div class="cell flex-1">
      <h3>Image</h3>
    </div>
    <div class="cell flex-1">
      <h3>Name</h3>
    </div>
    <div class="cell flex-3">
      <h3>Description</h3>
    </div>
    <div class="cell button-cell">
      <button md-button type="submit" class="btn-delete">
        <div class="button-content">
          <span>delete</span>
        </div>
      </button>
    </div>
</div>

<md-list class="list" layout="column">
  <div class="list-group" dnd-sortable-container [sortableData]="featuredCategoriesList.data">
    <div *ngFor="let category of featuredCategoriesList.data; let i = index" dnd-sortable (onDropSuccess)="dragAndDrop.dropElement(solution.action('order_categories'), featuredCategoriesList.data)" [sortableIndex]="i">
      <div layout="row" class="event-row clickable-row">
        <div class="cell flex-1" *ngIf="category.attributes">
          <img src="{{ category.attributes.image_url }}" alt="category-image" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'"/>
        </div>
        <div class="cell flex-1 name-cell" *ngIf="category.attributes">
          <p>{{ category.attributes.name }}</p>
        </div>
        <div class="cell flex-3 name-cell" *ngIf="category.attributes">
          <p>{{ category.attributes.description }}</p>
        </div>
        <div class="cell button-cell">
          <button md-button type="submit" class="btn-delete" (click)="deleteWarningDialog(category, 'Are you sure you want to delete this featured category?')">
            <div class="button-content">
              <span>delete</span>
            </div>
          </button>
        </div>
      </div>
      <md-divider></md-divider>
    </div>
  </div>
</md-list>
