<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>{{ categoryField ? 'Edit ' + categoryField.attributes.name : 'Create New Category Field' }}</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('name')">
      <h3>Name</h3>
      <app-text-input [action]="action" field="name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('label')">
      <h3>Label</h3>
      <app-text-input [action]="action" field="label" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('data_type')">
      <h3>Type</h3>
      <md-select formControlName="data_type">
        <md-option *ngFor="let dt of action.field('data_type').options" [value]="dt.value">{{ dt.label }}</md-option>
      </md-select>
    </div>
    <button *ngIf="!customValuesOpen" md-button (click)="showCustomValues()" class="btn-action">Define Custom Values</button>
    <div class="sv-container" *ngIf="customValuesOpen">
      <div layout="row" layout-align="space-between">
        <h3>Data Values</h3>
      </div>
      <div *ngFor="let value of customValues">
        <div layout="row" layout-align="space-between">
          <md-input-container class="right-container">
            <input value="{{ value }}" mdInput #cvInput>
          </md-input-container>
          <button md-button type="submit" class="btn-action btn-remove" (click)="onRemoveValue(value)">Remove</button>
        </div>
      </div>
      <div layout="row" layout-align="space-between">
        <md-input-container class="right-container">
          <input #newVal mdInput>
        </md-input-container>
        <button md-button (click)="onAddValue(newVal)" class="btn-action" [disabled]="!newVal.value">Add</button>
      </div>
    </div>
    <div *ngIf="action.field('filterable')">
      <h3>Filterable</h3>
      <md-checkbox [checked]="action.field('filterable').value" formControlName="filterable"></md-checkbox>
    </div>
    <div *ngIf="action.field('emphasized')">
      <h3>Emphasized</h3>
      <md-checkbox [checked]="action.field('emphasized').value" formControlName="emphasized"></md-checkbox>
    </div>
    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
          <div class="button-content">
            <md-icon>insert_invitation</md-icon>
            <span>{{ categoryField ? 'Update' : 'Create' }}</span>
          </div>
        </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
