<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-2">
    <div layout="row" class="cell flex-3">
      <app-avatar></app-avatar>
    </div>
    <div layout="row" class="cell flex-9">
      <h3>User</h3>
    </div>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Email</h3>
  </div>
  <div layout="row" class="cell flex-1">
    <h3>Company</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Notes</h3>
  </div>
  <div layout="row" class="cell flex-1">
    <h3>Date</h3>
  </div>
  <div layout="row" class="cell flex-1" *ngIf="!isArchived">
    <h3>Ads</h3>
  </div>
  <div class="cell flex-3"></div>
</div>

<md-list class="list">
  <ng-template ngFor let-rfq [ngForOf]="rfqs.data">
    <div class="list-row" layout="row" [class.selected]="rfq.attributes.selected">
      <div layout="row" class="cell flex-2">
        <div layout="row" class="cell flex-3">
          <app-avatar *ngIf="rfq.relItem(rfqs.included, 'user')" [src]="rfq.relItem(rfqs.included, 'user').attributes.profile_image_url"></app-avatar>
        </div>
        <div layout="row" class="cell flex-9">
          <div layout-align="center start">
            <span>{{ rfq.relItem(rfqs.included, 'user').attributes.first_name }} {{ rfq.relItem(rfqs.included, 'user').attributes.last_name }}</span>
          </div>
        </div>
      </div>
      <div layout="row" class="cell flex-2">
        <div layout-align="center start">
          <span>{{ rfq.relItem(rfqs.included, 'user').attributes.email }}</span>
        </div>
      </div>
      <div layout="row" class="cell flex-1">
        <div layout-align="center start">
          <span>{{ rfq.relItem(rfqs.included, 'user').attributes.company_name }}</span>
        </div>
      </div>
      <div layout="row" class="cell flex-2">
        <div>
          <span *ngIf="rfq.attributes.name">Name: {{ rfq.attributes.name }}</span><br *ngIf="rfq.attributes.name">
          <span *ngIf="rfq.attributes.memo">Memo: {{ rfq.attributes.memo }}</span>
        </div>
      </div>
      <div layout="row" class="cell flex-1">
        <span>{{ rfq.attributes.created_at | date : 'short' }}</span>
      </div>
      <div layout="row" class="cell flex-1" *ngIf="!rfq.attributes.deleted_at">
        <md-checkbox class="ads" [checked]="rfq.attributes.ads" disabled></md-checkbox>
      </div>
      <div layout="row" class="cell flex-3">
        <div layout="row">
          <button md-button type="button" name="button" class="btn-action" [routerLink]="[rfq.id]" (click)="markAsSelected(rfq)">Details</button>
          <button *ngIf="!rfq.attributes.deleted_at" md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!rfq.action('delete')" (click)="deleteWarningDialog(rfq, 'Are you sure you want to delete this rfq?')">delete</button>
        </div>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
