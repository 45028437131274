<div class="page-container" layout="column">
    <div layout="row" class="page-container-inner">
      <app-side-nav #sideNav></app-side-nav>
      <div class="right-container">
        <app-mobile-header [title]="'Marketplace'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>
        <app-main-tabs #mainTabs [tabs]="tabs" (tabChanged)="tabChanged($event)"></app-main-tabs>
        <app-list-container [list]="offers" [returnParams]="currentQuery" [showFilters]="true" [listType]="'offers'">
          <app-offer-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed" [pending]="pending"></app-offer-list>
        </app-list-container>
      </div>
    </div>
</div>

<app-side-drawer returnLink="marketplace" [returnParams]="currentQuery">
    <router-outlet (activate)="openDrawer($event)" (deactivate)="closeDrawer($event)"></router-outlet>
</app-side-drawer>