<div class="page-container" layout="column">
    <div layout="row" class="page-container-inner">
        <app-side-nav #sideNav></app-side-nav>
        <div class="right-container">
            <app-mobile-header [title]="'Solutions'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>
            <app-main-tabs #mainTabs [tabs]="tabs" (tabChanged)="tabChanged($event)"></app-main-tabs>
  
            <app-list-container [list]="solutions" [returnParams]="currentQuery" [showFilters]="false" [listType]="'solutions'">
            <app-solution-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed" [hidden]="hidden"></app-solution-list>
            </app-list-container>
        </div>
    </div>
</div>
  
<app-side-drawer returnLink="solutions" [returnParams]="currentQuery">
    <router-outlet (activate)="openDrawer($event)" (deactivate)="closeDrawer($event)"></router-outlet>
</app-side-drawer>