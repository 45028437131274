
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-2 name-cell">
    <h3>name</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>custom fields</h3>
  </div>
  <div class="cell flex-1 button-cell">
  </div>
  <button md-button type="button" class="btn-add" (click)="newCategory()" *ngIf="createAction && !hidden">
    <md-icon>add</md-icon>
  </button>
</div>
<md-list class="list">
  <div class="list-group" dnd-sortable-container [sortableData]="categories.data">
    <div *ngFor="let category of categories.data; let i = index" dnd-sortable (onDropSuccess)="dragAndDrop.dropElement(categories.action('order'), categories.data)" [sortableIndex]="i">
      <div class="list-row" layout="row" [class.selected]="category.attributes.selected">
        <div layout="row" class="cell flex-2 name-cell">
          <span>{{ category.attributes.name }}</span>
        </div>
        <div layout="row" class="cell flex-2 fields-cell">
          <span>{{ fieldNames(category) }}</span>
        </div>
        <div layout="row" class="cell flex-1 button-cell">
          <div layout="row">
            <button md-button type="button" name="button" class="btn-action" [routerLink]="[category.id, 'fields']" (click)="markAsSelected(category)">Edit</button>
            <button *ngIf="!hidden" md-button class="btn-action btn-hide" type="button" name="button" [disabled]="!category.action('hide')" (click)="hideWarningDialog(category, 'Are you sure you want to hide this category?')">hide</button>
            <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!category.action('delete')" (click)="deleteWarningDialog(category, 'Are you sure you want to delete this category?')">delete</button>
            <button *ngIf="hidden" md-button class="btn-action btn-hide" type="button" name="button" (click)="unhideCategory(category)">unhide</button>
          </div>
        </div>
      </div>
      <md-divider></md-divider>
    </div>
  </div>
</md-list>
