<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="content">
        <div *ngIf="action.field('name')">
            <h3>Name</h3>
            <app-text-input [action]="action" field="name" placeholder=""></app-text-input>
        </div>

        <div *ngIf="action.field('description')" class="description-container">
            <h3>Description</h3>
            <md-editor formControlName="description"></md-editor>
        </div>

        <div *ngIf="action.field('image_url')" class="image-holder">
            <app-s3-uploader [showImage]="true" [action]="action" field="image_url" config="brand_image" defaultStatus="Drag and Drop Image Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
        </div>

        <div class="button-container" layout="row">
            <button md-button type="submit" class="btn-action" [disabled]="!form.valid">
                <div class="button-content">
                    <md-icon>insert_invitation</md-icon>
                    <span>{{ isNew ? 'Create' : 'Update' }}</span>
                </div>
            </button>

            <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
                <div class="button-content">
                    <md-icon>clear</md-icon>
                    <span>Cancel</span>
                </div>
            </button>
        </div>
    </div>
</form>
<div *ngIf="!form">
    <md-list>
        <div class="title" layout="row" layout-align="start center">
            <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
            <h2>Oops! Something went wrong.</h2>
        </div>
    </md-list>
</div>