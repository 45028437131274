<button md-button type="button" class="add-button" [routerLink]="[currentQuery.category_id, 'create']" [queryParams]="currentQuery" *ngIf="currentQuery.category_id">
  <md-icon>add</md-icon>
</button>
<button md-button type="button" class="add-button import" [routerLink]="[currentQuery.category_id, 'import']" *ngIf="currentQuery.category_id">
  <md-icon>file_upload</md-icon>
</button>
<button md-button type="button" class="add-button export" [routerLink]="[currentQuery.category_id, 'export']" [queryParams]="exportParams" *ngIf="currentQuery.category_id">
  <md-icon>file_download</md-icon>
</button>
<button md-button type="button" class="add-button delete-all" (click)="deleteCategoryParts('This will delete all parts for selected category. Are you sure?')" *ngIf="currentQuery.category_id && deleteCategoryPartsAction">
  <md-icon>delete</md-icon>
</button>
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-2">
    <div class="part-image-wrap"></div>
  </div>
  <div layout="row" class="cell flex-3">
    <h3>part number</h3>
  </div>
  <div layout="row" class="cell flex-3">
    <h3>product name</h3>
  </div>
  <div class="cell flex-4"></div>
</div>

<md-list class="list">
  <ng-template ngFor let-part [ngForOf]="parts.data">
    <div class="list-row" layout="row" [class.selected]="part.attributes.selected">
      <div layout="row" class="cell flex-2">
        <div class="part-image-wrap" *ngIf="part.attributes.assets && part.attributes.assets.image_url">
          <img [src]="part.attributes.assets.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'">
        </div>
      </div>
      <div layout="row" class="cell flex-3">
        <span class="name">{{ part.attributes.part_number }}</span>
      </div>
      <div layout="row" class="cell flex-3">
        <a class="name" *ngIf="part.relItem(parts.included, 'product')" [routerLink]="['../products', part.relItem(parts.included, 'product').id]">{{ part.relItem(parts.included, 'product').attributes.name }}</a>
      </div>
      <div layout="row" class="cell flex-4">
        <div layout="row" class="button-container">
          <button md-button type="button" name="button" class="btn-action" [routerLink]="[part.id]" (click)="markAsSelected(part)">Edit</button>
          <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!part.action('delete')" (click)="deleteWarningDialog(part, 'Are you sure you want to delete this part?')">delete</button>
        </div>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
