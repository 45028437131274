<div class="page-container" layout="column">
  <div layout="row" class="page-container-inner">
    <app-side-nav #sideNav></app-side-nav>
    <div class="right-container">
      <app-mobile-header [title]="'Suppliers'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>

      <app-list-container [list]="suppliers" [returnParams]="currentQuery" [showFilters]="false" [listType]="'suppliers'">
        <app-suppliers-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed"></app-suppliers-list>
      </app-list-container>
    </div>
  </div>
</div>
