<div md-dialog class="dialog">
  <div class="header">
    <h1 md-dialog-title>Add new featured part</h1>
  </div>
  <div md-dialog-content class="content">
    <md-input-container class="input-container">
      <input mdInput placeholder="Start typing part name" (keyup)="onSearchChange($event.target.value)"/>
    </md-input-container>

    <md-divider></md-divider>

    <md-list class="list" *ngIf="parts">
      <ng-template ngFor let-part [ngForOf]="parts.data">
        <div class="list-row" layout="row" [class.selected]="part.attributes.selected">
          <div layout="row" class="cell flex">
            <div class="part-image-wrap" *ngIf="part.attributes.assets && part.attributes.assets.image_url">
              <img [src]="part.attributes.assets.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'">
            </div>
          </div>
          <div layout="row" class="cell flex-3 name">
            <span class="name">{{ part.attributes.name }}</span>
          </div>
          <div layout="row" class="cell flex">
            <div layout="row">
              <button md-button type="button" name="button" class="btn-action" (click)="dialogRef.close(part)">Select</button>
            </div>
          </div>
        </div>
        <md-divider></md-divider>
      </ng-template>
    </md-list>
  </div>
  <div md-dialog-actions align="end">
    <button md-button (click)="dialogRef.close()">CANCEL</button>
  </div>
</div>