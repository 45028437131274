<md-list class="list" layout="column">
  <div *ngFor="let message of quoteMessages.data" layout="row" class="message-wrap">
    <div class="message-user" layout="row">
      <div class="avatar-wrap">
        <app-avatar *ngIf="messageUser(message)" [src]="messageUser(message).attributes.profile_image_url"></app-avatar>
      </div>
      <span *ngIf="messageUser(message)" class="username">{{ messageUser(message).attributes.first_name }} {{ messageUser(message).attributes.last_name }}</span>
    </div>
    <div class="message-body" layout="column" layout-align="start start">
      <p>{{ message.attributes.body }}</p>
      <small>{{ message.attributes.created_at | date : 'short' }}</small>
    </div>
    <md-divider></md-divider>
  </div>
  <div class="memo-wrapper message-wrap" layout="row" *ngIf="memoUser && memo">
    <div class="message-user" layout="row">
      <div class="avatar-wrap">
        <app-avatar [src]="memoUser.attributes.profile_image_url"></app-avatar>
      </div>
      <span *ngIf="memoUser" class="username">{{ memoUser.attributes.first_name }} {{ memoUser.attributes.last_name }}</span>
    </div>
    <div class="message-body" layout="column" layout-align="start start">
      <p>{{ memo }}</p>
      <small>RFQ Memo</small>
    </div>
  </div>
</md-list>


<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="content">
    <div *ngIf="action.field('body')">
      <h3>Message</h3>
      <app-text-input [action]="action" field="body" placeholder=""></app-text-input>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>send</md-icon>
          <span>Send</span>
        </div>
      </button>
    </div>
  </div>
</form>
