<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Import products into {{ category.attributes.name }} category</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('file_url')">
      <app-s3-uploader [action]="action" field="file_url" config="product_imports" defaultStatus="CSV File" failStatus="Invalid File - Please Upload a CSV file" successStatus="File Uploaded!" [allowedExtensions]="['.csv']"></app-s3-uploader>
    </div>

    <div *ngIf="report">
      Report ID: {{ report.id }}<br>
      {{ report.attributes.label }}
      <br><br>
      <a class="report-link" [routerLink]="['/reports']">View Reports</a>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="button" (click)="importDialog()" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>Import</span>
        </div>
      </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>

    <div *ngIf="action.formErrors.file_url" class="form-error">
      <h2 class="error">Data Import Failed</h2>
      <span><pre>{{ action.formErrors.file_url | cleanString }}</pre></span>
    </div>
  </div>
</form>

<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
