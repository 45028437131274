<div class="page-container" layout="column">
  <div layout="row" class="page-container-inner">
    <app-side-nav #sideNav></app-side-nav>
    <div class="right-container">
      <app-mobile-header [title]="'Quotes'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>
      <app-main-tabs #mainTabs [tabs]="tabs" (tabChanged)="tabChanged()"></app-main-tabs>
      <app-list-container [list]="quotes" [returnParams]="currentQuery" [showFilters]="true" [listType]="'quotes'">
        <app-quote-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed"></app-quote-list>
      </app-list-container>
    </div>
  </div>
</div>

<app-side-drawer returnLink="quotes" [returnParams]="currentQuery">
  <router-outlet (activate)="openDrawer($event)" (deactivate)="closeDrawer($event)"></router-outlet>
</app-side-drawer>
