<div class="header">
  <h1 md-dialog-title>Attention</h1>
  <md-icon (click)="dialogRef.close()">clear</md-icon>
</div>
<div md-dialog-content>
  <span>Do you want to overwrite or append to current parts?</span>
</div>
<div md-dialog-actions align="end">
  <button md-button (click)="dialogRef.close('overwrite')">OVERWRITE</button>
  <button md-button (click)="dialogRef.close('appendTop')">APPEND TO TOP</button>
  <button md-button (click)="dialogRef.close('appendBottom')">APPEND TO BOTTOM</button>
</div>