<div [formGroup]="form" class="s3-uploader" [ngClass]="{compact: compact !== false, fail: status === failStatus}" [class.compact]="compact !== false">
  <div appFileDrop class="upload-box"  (filesChangeEmitter)="filesDropped($event)" (filesInvalidEmitter)="invalidFiles($event)" [allowedExtensions]="allowedExtensions" (mouseenter)="setHovering(true)" (mouseleave)="setHovering(false)">
    <img *ngIf="imageUrl" src="{{ imageUrl }}" />
    <p *ngIf="showStatus" class="status">{{ status }}</p>
    <input #fileUpload type="file" accept="{{ allowedExtensions.join(', ') }}" (change)="filesChanged($event)">
    <div class="progress-wrapper">
      <md-progress-bar mode="determinate" value="{{ progress }}" *ngIf="showProgressBar"></md-progress-bar>
    </div>
    <p class="text-sm color-red fail-status">{{ failStatus }}</p>
  </div>
  <button md-button primary-bg-color class="btn-upload" type="button" (click)="fileUpload.click()">{{ buttonLabel }}</button>
</div>
