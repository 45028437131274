<div class="page-container" layout="column">
  <div layout="row" class="page-container-inner">
    <app-side-nav #sideNav></app-side-nav>
    <div class="right-container">
      <app-mobile-header [title]="'Reports'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>
      <app-main-tabs #mainTabs [tabs]="tabs" (tabChanged)="tabChanged($event)"></app-main-tabs>

      <app-list-container [list]="reports" [returnParams]="currentQuery" [showFilters]="false" [listType]="'reports'">
        <app-report-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed" [pending]="pending"></app-report-list>
      </app-list-container>
    </div>
  </div>
</div>
