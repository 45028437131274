<div class="filters">
  <form [formGroup]="form" (ngSubmit)="submit()" flex layout="column" layout-align="center">
    <div *ngIf="form.controls['search']">
      <h2>Search</h2>
      <div class="flex-1"></div>
      <h3 class="keyword">Keyword</h3>
      <div layout="row" layout-align="center center">
        <!-- <app-text-input [action]="action" field="search" autofocus></app-text-input> -->
        <md-input-container>
          <div *ngIf="showTextError" class="form-error">
            Keyword Must Be at least Two Characters
          </div>
          <input mdInput formControlName="search" type="search" placeholder="">
        </md-input-container>
      </div>
      <p class="text-sm" *ngIf="searchDescription">{{ searchDescription }}</p>
    </div>
    <div class="flex-1"></div>

    <div *ngFor="let filter of filters" layout="column">
      <h3 *ngIf="filter.type !== 'boolean'">Filter By {{ filter.name }}</h3>
      <div [ngSwitch]="filter.type" layout="column">
        <div *ngSwitchCase="'select'" layout="column">
          <md-select formControlName="{{ filter.name }}" (ngModelChange)="changeSelected($event)">
            <md-option value="">All</md-option>
            <md-option *ngFor="let option of filter.options" [value]="option.value">{{ option.label }}</md-option>
            <md-option *ngIf="filter.name == 'agent'" value="none">No Agent Assigned</md-option>
          </md-select>
        </div>
        <div *ngSwitchCase="'boolean'" layout="column">
          <md-slide-toggle *ngIf="currentlySelected" formControlName="{{ filter.name }}">{{ filter.label }}</md-slide-toggle>
        </div>
        <div *ngSwitchCase="'text'" layout="row" layout-align="center center">
          <md-input-container>
            <div *ngIf="showTextError" class="form-error">Keyword Must Be At Least Two Characters</div>
            <input mdInput formControlName="{{ filter.name }}" type="{{ filter.type }}">
          </md-input-container>
        </div>
        <div *ngSwitchCase="'number'" layout="row" layout-align="center center">
          <md-input-container>
            <input mdInput formControlName="{{ filter.name }}" type="{{ filter.type }}">
          </md-input-container>
        </div>
      </div>
    </div>
    <div class="flex-1"></div>

    <ng-template [ngIf]="sort">
    <h3>{{ 'sort' | label }}</h3>
    <div *ngIf="sort">
      <md-select formControlName="sort">
        <md-option *ngFor="let option of sort.options" [value]="option.value">{{ option.label }}</md-option>
      </md-select>
    </div>
    <!-- <div class="flex-6"></div> -->
    </ng-template>

    <div class="button-container" layout="row">
      <button md-button type="button" class="btn-cancel" (click)="close()">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
      <button md-button type="submit" class="btn-action">
        <div class="button-content">
          <md-icon>tune</md-icon>
          <span>Apply Filter</span>
        </div>
      </button>
    </div>
    <div class="flex-18"></div>
  </form>
</div>
