<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
    <md-list>
        <div class="title" layout="row" layout-align="start center">
        <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
        <h2>{{ 'Edit Offer #' + offer.id }}</h2>
        </div>
        <md-divider></md-divider>
    </md-list>
    <div class="content">
        <div *ngIf="offer.relItem(offer.included, 'part')">
            <h3>Part Number</h3>
            <p class="part-number">{{ offer.relItem(offer.included, 'part').attributes.part_number }}</p>
        </div>
        <div *ngIf="!offer.relItem(offer.included, 'part')">
            <div *ngIf="action.field('custom_brand')">
                <h3>Custom Brand</h3>
                <app-text-input [action]="action" field="custom_brand" placeholder=""></app-text-input>
            </div>
            <div *ngIf="action.field('custom_part')">
                <h3>Custom Part Number</h3>
                <app-text-input [action]="action" field="custom_part" placeholder=""></app-text-input>
            </div>
        </div>
        <div *ngIf="action.field('quantity')">
            <h3>Quantity</h3>
            <app-text-input [action]="action" field="quantity" placeholder=""></app-text-input>
        </div>
        <div *ngIf="action.field('status')" layout="column">
            <h3>Status</h3>
            <md-radio-group formControlName="status">
                <md-radio-button value="{{ option.value }}" *ngFor="let option of action.field('status').options">{{ option.label }}</md-radio-button>
            </md-radio-group>
        </div>
        <div *ngIf="action.field('city')">
            <h3>City</h3>
            <app-text-input [action]="action" field="city" placeholder=""></app-text-input>
        </div>
        <div *ngIf="action.field('state')">
            <h3>State</h3>
            <app-text-input [action]="action" field="state" placeholder=""></app-text-input>
        </div>
        <div *ngIf="action.field('country')">
            <h3>Country</h3>
            <app-text-input [action]="action" field="country" placeholder=""></app-text-input>
        </div>
        <div *ngIf="action.field('approved')" layout="column">
            <h3>Approve</h3>
            <md-checkbox formControlName="approved">{{ form.value.approved ? 'Approved' : 'Waiting for approval' }}</md-checkbox>
        </div>
        <div class="button-container" layout="row">
            <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
                <div class="button-content">
                    <md-icon>insert_invitation</md-icon>
                    <span>Update</span>
                </div>
            </button>
            <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
                <div class="button-content">
                    <md-icon>clear</md-icon>
                    <span>Cancel</span>
                </div>
            </button>
        </div>
    </div>
</form>
<div *ngIf="!form">
    <md-list>
        <div class="title" layout="row" layout-align="start center">
        <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
        <h2>Oops! Something went wrong.</h2>
        </div>
    </md-list>
</div>
