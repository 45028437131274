<button md-button type="button" class="add-button" primary-bg-color routerLink="create" *ngIf="createAction && !hidden">
  <md-icon>add</md-icon>
</button>
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-2">
    <div class="brand-image-wrap"></div>
  </div>
  <div layout="row" class="cell flex-1">
    <h3>id</h3>
  </div>
  <div layout="row" class="cell flex-3">
    <h3>name</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Products</h3>
  </div>
  <div class="cell flex-4"></div>
</div>

<md-list class="list">
  <div class="list-group" dnd-sortable-container [sortableData]="brands.data">
    <div *ngFor="let brand of brands.data; let i = index" dnd-sortable (onDropSuccess)="dragAndDrop.dropElement(brands.action('order'), brands.data)" [sortableIndex]="i">
      <div class="list-row" layout="row" [class.selected]="brand.attributes.selected">
        <div layout="row" class="cell flex-2">
          <div class="brand-image-wrap"><img [src]="brand.attributes.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'"></div>
        </div>
        <div layout="row" class="cell flex-1">
          <span>{{ brand.id }}</span>
        </div>
        <div layout="row" class="cell flex-3">
          <span>{{ brand.attributes.name }}</span>
        </div>
        <div layout="row" class="cell flex-2">
          <span>{{ brand.attributes.products_count }}</span>
        </div>
        <div layout="row" class="cell flex-4">
          <div layout="row">
            <button md-button type="button" name="button" class="btn-action" [routerLink]="[brand.id]" (click)="markAsSelected(brand)">Edit</button>
            <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!brand.action('delete')" (click)="deleteWarningDialog(brand, 'Are you sure you want to delete this brand?')">delete</button>
          </div>
        </div>
      </div>
      <md-divider></md-divider>
    </div>
  </div>
</md-list>
