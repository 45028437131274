<div class="page-container" layout="column">
  <div layout="row" class="page-container-inner">
    <app-side-nav #sideNav></app-side-nav>
    <div class="right-container">
      <app-mobile-header [title]="'Rfqs'" (toggleNav)="sideNav.toggle()" hide-gt-xs></app-mobile-header>
      <app-main-tabs #mainTabs [tabs]="tabs" (tabChanged)="tabChanged($event)"></app-main-tabs>

      <app-list-container [list]="rfqs" [returnParams]="currentQuery" [showFilters]="false" [listType]="'rfqs'">
        <app-rfq-list listComponent #listComponent [navCollapsed]="sideNav.isCollapsed" [state]="currentQueryState"></app-rfq-list>
      </app-list-container>
    </div>
  </div>
</div>

<app-side-drawer returnLink="rfqs" [returnParams]="currentQuery">
  <router-outlet (activate)="openDrawer($event)" (deactivate)="closeDrawer($event)"></router-outlet>
</app-side-drawer>
