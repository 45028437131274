<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>{{user ? 'Edit ' + user.attributes.first_name : 'Invite New User' }} <span *ngIf="user.attributes.deleted_at">[Deleted Account]</span></h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('first_name')">
      <h3>First Name</h3>
      <app-text-input [action]="action" field="first_name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('last_name')">
      <h3>Last Name</h3>
      <app-text-input [action]="action" field="last_name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('email')">
      <h3>email</h3>
      <app-text-input [action]="action" field="email" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('phone')">
      <h3>phone</h3>
      <app-text-input [action]="action" field="phone" placeholder="" pattern="^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$"></app-text-input>
    </div>

    <div *ngIf="action.field('roles_payload')" layout="column">
      <h3>Role</h3>
      <div *ngFor="let option of action.field('roles_payload').options" layout="column" class="role-wrap">
        <p>{{ option.meta.description }}:</p>
        <md-checkbox [checked]="hasRole(option.value)" (change)="toggleRole(option.value)">{{ option.label }}</md-checkbox>
      </div>
    </div>

    <div>
      <h3>Addresses</h3>
      <div layout="column" *ngFor="let userAddress of (user.relItems(user.included, 'user_addresses')); index as i">
        <p>{{ i+1 }}. Address: {{ userAddress.attributes.address }} {{userAddress.attributes.city }} 
          {{ userAddress.attributes.state }} {{ userAddress.attributes.zip }} {{ userAddress.attributes.country }} 
        </p>
      </div>
    </div>

    <br>

    <div *ngIf="action.field('profile_image_url')">
      <app-s3-uploader [action]="action" [showImage]="true" field="profile_image_url" config="user_image" defaultStatus="Drag and Drop Profile Picture Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>{{ user ? 'Update' : 'Invite' }}</span>
        </div>
      </button>
      <button *ngIf="deleteAction && !isCurrentUser" md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!deleteAction || isCurrentUser" (click)="deleteWarningDialog(user, 'Are you sure you want to delete this user?')">Delete</button>
      <button *ngIf="activateAction && !isCurrentUser" md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!activateAction || isCurrentUser" (click)="activateWarningDialog(user, 'Are you sure you want to activate this user?')">Activate</button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
