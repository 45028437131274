<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>{{product ? 'Edit ' + product.attributes.name : 'Create New Product' }}</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('name')">
      <h3>Name</h3>
      <app-text-input [action]="action" field="name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('description')" class="description-container">
      <h3>Description</h3>
      <md-editor formControlName="description"></md-editor>
    </div>
    <div *ngIf="action.field('info')">
      <h3>Info</h3>
      <md-input-container>
        <textarea mdInput formControlName="info" rows="8"></textarea>
      </md-input-container>
    </div>
    <div *ngIf="action.field('brand_id')">
      <h3>Brand</h3>
      <md-select formControlName="brand_id">
        <md-option *ngFor="let brand of brands.data" [value]="brand.id">{{ brand.attributes.name }}</md-option>
      </md-select>
    </div>

    <br>

    <div [formGroup]="formCustom" *ngIf="formCustom && action.field('fields') && action.field('fields').value">
      <div *ngFor="let key of customFieldsKeys">
        <h3>{{ key | humanizeString }}</h3>
        <md-input-container>
          <input #input mdInput [formControlName]="key" type="text">
        </md-input-container>
      </div>
    </div>

    <br>

    <div *ngIf="action.field('assets') && action.field('assets').value">
      <app-s3-uploader [showImage]="true" [action]="action" field="assets" fieldKey="image_url" config="product_image" defaultStatus="Drag and Drop Image Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
      <!-- <app-s3-uploader [action]="action" field="assets" fieldKey="spec_sheet_doc_url" config="product_pdf" defaultStatus="Spec Sheet PDF" failStatus="Invalid File - Please Upload a PDF" successStatus="File Uploaded!" [allowedExtensions]="['.pdf']"></app-s3-uploader>
      <app-s3-uploader [action]="action" field="assets" fieldKey="drawing_doc_url" config="product_pdf" defaultStatus="Drawing PDF" failStatus="Invalid File - Please Upload a PDF" successStatus="File Uploaded!" [allowedExtensions]="['.pdf']"></app-s3-uploader>
      <app-s3-uploader [action]="action" field="assets" fieldKey="description_doc_url" config="product_pdf" defaultStatus="Description DOC PDF" failStatus="Invalid File - Please Upload a PDF" successStatus="File Uploaded!" [allowedExtensions]="['.pdf']"></app-s3-uploader> -->
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
          <div class="button-content">
            <md-icon>insert_invitation</md-icon>
            <span>{{ product ? 'Update' : 'Create' }}</span>
          </div>
        </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
