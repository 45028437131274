<div class="md2-datepicker-trigger">
  <button type="button"
          class="md2-datepicker-button"
          tabindex="-1"
          (click)="toggle()">
    <svg *ngIf="type==='date'" width="24" height="24" viewBox="0 0 24 24">
      <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path>
    </svg>
    <svg *ngIf="type==='time'" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"></path>
    </svg>
    <svg *ngIf="type==='datetime'" width="24" height="24" viewBox="0 0 24 24">
      <path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"></path>
    </svg>
  </button>
  <div class="md2-datepicker-input" [class.md2-datepicker-input-focused]="_inputFocused">
    <span *ngIf="!value && !_inputFocused" class="md2-datepicker-placeholder" [class.md2-floating-placeholder]="value || _inputFocused">{{ placeholder }}</span>
    <input type="text"
           readonly
           class="md2-datepicker-value"
           [tabindex]="tabindex"
           [disabled]="disabled"
           [value]="value | date:format"
           autocomplete="off"
           (change)="$event.stopPropagation()"
           (focus)="_handleFocus($event)"
           (blur)="_handleBlur($event)"
           (keydown)="_handleKeydown($event)"
           (click)="_handleFocus($event)" />
    <span *ngIf="!value || required || disabled" class="md2-datepicker-arrow" (click)="open()"></span>
    <span *ngIf="value && !required && !disabled"
          class="md2-datepicker-clear" (click)="_clearValue($event)">
      <svg viewBox="0 0 24 24" width="20" height="20">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </span>
  </div>
</div>
<ng-template #portal>
  <div class="md2-datepicker-panel" [@fadeInContent]="'showing'" (@fadeInContent.done)="_onPanelDone()"
       (keydown)="_handleKeydown($event)" [style.transformOrigin]="_transformOrigin"
       [class.md2-datepicker-panel-done-animating]="_panelDoneAnimating" tabindex="0"
       [attr.container]="container" [attr.mode]="mode">
    <div class="md2-datepicker-header">
      <div class="md2-datepicker-header-year"
           *ngIf="type!=='time'"
           [class.active]="_isYearsVisible"
           (click)="_showYear()">{{ date | date: 'y' }}</div>
      <div class="md2-datepicker-header-date-time">
        <span class="md2-datepicker-header-date"
              *ngIf="type!=='time'"
              [class.active]="_isCalendarVisible && !_isYearsVisible"
              (click)="_showCalendar()">
          {{ getDateLabel }}
        </span>
        <span class="md2-datepicker-header-time"
              *ngIf="type!=='date'"
              [class.active]="!_isCalendarVisible">
          <span class="md2-datepicker-header-hour"
                [class.active]="_clockView === 'hour'"
                (click)="_toggleHours('hour')">{{ getHours(date) }}</span>:<span class="md2-datepicker-header-minute"
                                                                                  [class.active]="_clockView === 'minute'"
                                                                                  (click)="_toggleHours('minute')">{{ getMinutes(date) }}</span>
          <span class="md2-datepicker-header-period">{{ getPeriod(date) }}</span>
        </span>
      </div>
    </div>
    <div class="md2-datepicker-content">
      <div class="md2-datepicker-calendar" [class.active]="_isCalendarVisible">
        <div class="md2-calendar-years" [class.active]="_isYearsVisible">
          <div class="md2-calendar-years-content">
            <div *ngFor="let y of _years"
                 class="md2-calendar-year"
                 [class.selected]="y === date.getFullYear()"
                 (click)="_setYear(y)">{{y}}</div>
          </div>
        </div>
        <div class="md2-calendar-month" [class.active]="!_isYearsVisible">
          <div class="md2-calendar-header">
            <div class="md2-button"
                 [class.disabled]="!_isBeforeMonth()"
                 (click)="_isBeforeMonth() && _updateMonth(-1)">
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              </svg>
            </div>
            <div class="md2-calendar-month-year" [@slideCalendar]="_calendarState">{{ getMonthLabel }}</div>
            <div class="md2-button"
                 [class.disabled]="!_isAfterMonth()"
                 (click)="_isAfterMonth() && _updateMonth(1)">
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
            </div>
          </div>
          <table class="md2-calendar-dates">
            <thead><tr><th *ngFor="let day of _weekDays">{{day.xshort}}</th></tr></thead>
            <tbody [@slideCalendar]="_calendarState">
              <tr *ngFor="let w of _dates">
                <td *ngFor="let d of w">
                  <div class="md2-calendar-day"
                       [class.today]="d.today"
                       [class.focus]="_util.isSameDay(date, d.date)"
                       [class.selected]="_util.isSameDay(selected, d.date)"
                       [class.disabled]="d.disabled"
                       [class.prev-month]="d.calMonth===_prevMonth"
                       [class.curr-month]="d.calMonth===_currMonth"
                       [class.next-month]="d.calMonth===_nextMonth"
                       (click)="_onClickDate($event,d)">{{d.index}}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <md2-clock [class.active]="!_isCalendarVisible"
                 [time]="time"
                 [view]="_clockView"
                 [interval]="5"
                 (timeChange)="_onTimeChange($event)"
                 (onHourChange)="_onHourChange($event)"
                 (onMinuteChange)="_onMinuteChange($event)"></md2-clock>
      <div class="md2-datepicker-actions">
        <div class="md2-button" (click)="close()">{{ cancelLabel }}</div>
        <div class="md2-button" (click)="_onClickOk()">{{ okLabel }}</div>
      </div>
    </div>
  </div>
</ng-template>
