<!-- <button md-button type="button" class="add-button" primary-bg-color routerLink="create">
  <md-icon>add</md-icon>
</button> -->
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-1">
    <h3>Rfq#</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Date</h3>
  </div>
  <div layout="row" class="cell flex-3">
    <h3>Customer</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>Status</h3>
  </div>
  <div class="cell flex-4"></div>
</div>

<md-list class="list">
  <ng-template ngFor let-quote [ngForOf]="quotes.data">
    <div class="list-row" layout="row" [class.selected]="quote.attributes.selected">
      <div layout="row" class="cell flex-1">
        <a class="id-field" [routerLink]="['../rfqs', quote.attributes.rfq_id]">{{ quote.attributes.rfq_id }}</a>
        <div *ngIf="quote.relItem(quotes.included, 'rfq').attributes.admin_notification" class="notification"></div>
      </div>
      <div layout="row" class="cell flex-2">
        {{ getRfqSubmitDate(quote) }}
      </div>
      <div layout="row" class="cell flex-3">
        <div>
          <a *ngIf="quoteUser(quote)" [routerLink]="['../users', quoteUser(quote).id]">{{ quoteUser(quote).attributes.first_name }} {{ quoteUser(quote).attributes.last_name }}</a>
          <div *ngIf="quoteUser(quote).relCompany(this.quotes.included, 'company_users')">Company: {{ quoteUser(quote).relCompany(quotes.included, 'company_users').attributes.name }}</div>
        </div>
      </div>
      <div layout="row" class="cell flex-2">
        {{ quote.attributes.status }}
      </div>
      <div layout="row" class="cell flex-4">
        <div layout="row">
          <button md-button type="button" name="button" class="btn-action" [routerLink]="['../quotes', quote.id, 'summary']" (click)="markAsSelected(quote)">Edit</button>
          <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!quote.action('delete')" (click)="deleteWarningDialog(quote, 'Are you sure you want to delete this quote?')">
              <md-icon >clear</md-icon>
          </button>
        </div>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
