<div class="table-header" layout="row" hide show-gt-xs>
  <button md-button type="button" class="btn-add" routerLink="create">
    <md-icon>add</md-icon>
  </button>
  <div class="cell flex-3">
    <h3>Name</h3>
  </div>
  <div class="cell flex-2">
    <h3>Type</h3>
  </div>
  <div class="cell flex-2">
    <h3>Filterable</h3>
  </div>
  <div class="cell button-cell">
    <button md-button type="submit" class="btn-action">
      <div class="button-content">
        <span>Edit</span>
      </div>
    </button>
    <button md-button type="submit" class="btn-delete">
      <div class="button-content">
        <span>delete</span>
      </div>
    </button>
  </div>
</div>

<md-list class="list" layout="column">
  <div *ngFor="let field of categoryFields">
    <div layout="row" class="event-row clickable-row">
      <div class="cell flex-3">
        <h3>{{ fieldName(field) }}</h3>
      </div>
      <div class="cell flex-2 type-cell">
        <p>{{ fieldType(field) }}</p>
      </div>
      <div class="cell flex-2 filterable-cell">
        <p>{{ filterable(field) }}</p>
      </div>
      <div class="cell button-cell">
        <button [routerLink]="[field.id]" md-button type="submit" class="btn-action">
          <div class="button-content">
            <span>Edit</span>
          </div>
        </button>
        <button md-button type="submit" class="btn-delete" (click)="deleteWarningDialog(field, 'Are you sure you want to delete this category field?')">
          <div class="button-content">
            <span>delete</span>
          </div>
        </button>
      </div>
    </div>
    <md-divider></md-divider>
  </div>
</md-list>


<app-side-drawer #sideDrawer [returnLink]="drawerReturnLink">
  <router-outlet (activate)="openDrawer()" (deactivate)="closeDrawer($event)"></router-outlet>
</app-side-drawer>
