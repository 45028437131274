<div class="table-header" layout="row" hide show-gt-xs>
    <button md-button type="button" class="btn-add" (click)="addFeaturedBrandDialog()" *ngIf="!checkBrandsEmpty()">
      <md-icon>add</md-icon>
    </button>
    <div class="cell flex-1">
      <h3>Image</h3>
    </div>
    <div class="cell flex-1">
      <h3>Name</h3>
    </div>
    <div class="cell flex-2">
      <h3>Description</h3>
    </div>
    <div class="cell button-cell">
      <button md-button type="submit" class="btn-delete">
        <div class="button-content">
          <span>delete</span>
        </div>
      </button>
    </div>
</div>

<md-list class="list" layout="column">
  <div class="list-group" dnd-sortable-container [sortableData]="featuredBrandsList.data">
    <div *ngFor="let brand of featuredBrandsList.data; let i = index" dnd-sortable (onDropSuccess)="dragAndDrop.dropElement(category.action('order_brands'), featuredBrandsList.data)" [sortableIndex]="i">
      <div layout="row" class="event-row clickable-row">
        <div class="cell flex-1" *ngIf="brand.attributes">
          <img src="{{ brand.attributes.image_url }}" alt="brand-image"/>
        </div>
        <div class="cell flex-1 name-cell" *ngIf="brand.attributes">
          <p>{{ brand.attributes.name }}</p>
        </div>
        <div class="cell flex-2 name-cell" *ngIf="brand.attributes">
          <p>{{ brand.attributes.description }}</p>
        </div>
        <div class="cell button-cell">
          <button md-button type="submit" class="btn-delete" (click)="deleteWarningDialog(brand, 'Are you sure you want to delete this part from featured parts?')">
            <div class="button-content">
              <span>delete</span>
            </div>
          </button>
        </div>
      </div>
      <md-divider></md-divider>
    </div>
  </div>
</md-list>
