<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Export {{ category.attributes.name }} category products csv</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="exportedCSVURL">
      <a [href]="exportedCSVURL" target="_blank">Download CSV File</a>
    </div>
    <div *ngIf="report">
      Report ID: {{ report.id }}<br>
      {{ report.attributes.label }}
      <br><br>
      <a class="report-link" [routerLink]="['/reports']">View Reports</a>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>Export</span>
        </div>
      </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>

<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
