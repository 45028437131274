<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>RFQ Detail #{{ rfq.id }}</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div>
      <div layout="row" class="product-wrapper">
        <div class="flex-4 label">Part Number</div>
        <div class="flex-4 label">Model Number</div>
        <div class="flex-4 label">Quantity</div>
      </div>
      <div layout="column" *ngFor="let rfqPart of rfq.relItems(rfq.included, 'rfq_parts')">
        <div layout="row">
          <div class="flex-4 content-line">
            <span *ngIf="rfqPart.relItem(rfq.included, 'part')">{{ rfqPart.relItem(rfq.included, 'part').attributes.part_number }}</span>
            <span *ngIf="!rfqPart.relItem(rfq.included, 'part')">{{ rfqPart.attributes.custom_name }}</span>
          </div>
          <div class="flex-4 content-line">
            <span *ngIf="rfqPart.relItem(rfq.included, 'part')">{{ rfqPart.relItem(rfq.included, 'part').attributes.model_number }}</span>
            <span *ngIf="!rfqPart.relItem(rfq.included, 'part')">{{ rfqPart.attributes.custom_description }}</span>
          </div>
          <div class="flex-4 content-line">
            {{ rfqPart.attributes.quantity }}
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div>
      <div class="label">Memo:</div>
      <span class="content-line">{{ rfq.attributes.memo }}</span>
    </div>

    <hr>

    <div>
      <div class="label">Date:</div>
      <span class="content-line">{{ rfq.attributes.created_at | date : 'short' }}</span>
    </div>

    <hr>

    <div>
      <div class="label">End User:</div>
      <span class="content-line">{{ setRfqBooleans(rfq.attributes.is_end_user) }}</span>
    </div>

    <hr>

    <div>
      <div class="label">Need shipping quote:</div>
      <span class="content-line">{{ setRfqBooleans(rfq.attributes.is_need_shipping_quote) }}</span>
    </div>

    <hr>

    <div>
      <div class="label">Reseller:</div>
      <span class="content-line">{{ setRfqBooleans(rfq.attributes.is_reseller) }}</span>
    </div>

    <hr>

    <div>
      <div class="label">Shipping address:</div>
      <div class="shipping">
        <span *ngIf="rfq.attributes.shipping_address" class="content-line">{{rfq.attributes.shipping_address}} </span>
      </div>
    </div>


    <br>
    <hr>

    <!-- <div>
      <div class="label">Submit to a supplier:</div>
      <md-select formControlName="company_id" (change)="fetchCompanyUsers($event)" *ngIf="companies">
        <md-option value="">Select supplier company</md-option>
        <md-option *ngFor="let company of companies.data" [value]="company.links.agents">{{ company.attributes.name }}</md-option>
      </md-select>

      <md-select formControlName="agent_id" *ngIf="companyUsers">
        <md-option value="">Select agent</md-option>
        <md-option *ngFor="let agent of companyUsers.data" [value]="agent.id">{{ agent.attributes.first_name }} {{ agent.attributes.last_name }}</md-option>
      </md-select>
    </div> -->

    <!-- <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>Submit</span>
        </div>
      </button>
    </div> -->
  </div>
</form>
