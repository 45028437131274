<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>{{brand ? 'Edit ' + brand.attributes.name : 'Create New Brand' }}</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('name')">
      <h3>Name</h3>
      <app-text-input [action]="action" field="name" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('slug')">
      <h3>Slug (used in url)</h3>
      <app-text-input [action]="action" field="slug" placeholder="" pattern="^[A-Za-z0-9\-]*$"></app-text-input>
    </div>

    <div *ngIf="action.field('description')" class="description-container">
      <h3>Description</h3>
      <md-editor formControlName="description"></md-editor>
    </div>

    <div class="checkbox-container" *ngIf="action.field('hidden')">
      <h3>Hide this brand</h3>
      <md-checkbox [checked]="action.field('hidden').value" formControlName="hidden"></md-checkbox>
    </div>

    <br>

    <div *ngIf="action.field('image_url')">
      <h3>Image</h3>
      <app-s3-uploader [showImage]="true" [action]="action" field="image_url" config="brand_image" defaultStatus="Drag and Drop Image Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
    </div>

    <div *ngIf="action.field('cover_image_url')">
      <h3>Cover Image</h3>
      <app-s3-uploader [showImage]="true" [action]="action" field="cover_image_url" config="brand_cover_image" defaultStatus="Drag and Drop Image Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
    </div>

    <div class="brand-description" *ngIf="categories && categories.data">
        <h3>Custom brand page description</h3>
        <md-select placeholder="Select a category" *ngIf="action.field('category_id')" formControlName="category_id" (change)="changeDescription($event)">
            <md-option *ngFor="let category of categories.data" [value]="category.id">{{ category.attributes.name }}</md-option>
        </md-select>
        <h3>Description for selected category</h3>
        <div class="brand-description-contanier" *ngIf="action.field('category_description')">
            <md-editor formControlName="category_description"></md-editor>
        </div>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
          <div class="button-content">
            <md-icon>insert_invitation</md-icon>
            <span>{{ brand ? 'Update' : 'Create' }}</span>
          </div>
        </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
