<!-- <button md-button type="button" class="add-button" primary-bg-color routerLink="create" *ngIf="createAction">
  <md-icon>add</md-icon>
</button> -->
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-3">
    <app-avatar></app-avatar>
    <h3>name</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>users</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>industry</h3>
  </div>
  <div class="cell flex-3">
    <h3>role</h3>
  </div>
  <div class="cell flex-2"></div>
</div>

<md-list class="list">
  <ng-template ngFor let-company [ngForOf]="companies.data">
    <div class="list-row" layout="row" [class.selected]="company.attributes.selected">
      <div layout="row" class="cell flex-3">
        <app-avatar [src]="company.attributes.image_url"></app-avatar>
        <span>{{ company.attributes.name }}</span>
      </div>
      <div layout="row" class="cell flex-2">
        <span>{{ company.attributes.users_count }}</span>
      </div>
      <div layout="row" class="cell flex-2">
        <span>{{ company.attributes.industry }}</span>
      </div>
      <div layout="row" class="cell flex-3">
        <span class="name">{{ company.attributes.roles }}</span>
      </div>
      <div layout="row" class="cell flex-2">
        <div layout="row">
          <button md-button type="button" name="button" class="btn-action" [routerLink]="[company.id]" (click)="markAsSelected(company)">Edit</button>
          <!-- <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!company.action('delete')" (click)="deleteWarningDialog(company, 'Are you sure you want to delete this company?')">delete</button> -->
        </div>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
