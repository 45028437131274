<div class="supplier-container">
  <md-grid-list cols="5" rowHeight="4:3" gutterSize="25px">
    <md-grid-tile class="supplier-grid" *ngFor="let supplier of suppliers.data">
      <div class="grid-content">
        <div class="supplier-details">
          <h3>{{ supplier.attributes.name }}</h3>
          <span>{{ supplier.attributes.country }}</span>
        </div>
      </div>
    </md-grid-tile>
  </md-grid-list>
</div>
