<div class="nav">
  <app-top-nav hide show-gt-xs></app-top-nav>
  <div class="nav-sections" primary-color>
    <div class="dropdown">
      <button md-button class="dropdown-btn">
        <div class="icon-container">
          <md-icon class="icon">group</md-icon>
          <h3>crm</h3>
        </div>
      </button>
      <div class="dropdown-content">
        <div class="dropdown-content">
          <button md-button routerLink="/users" [disabled]="usersRL.isActive" routerLinkActive="is-active" #usersRL="routerLinkActive" *ngIf="linkAvailable('users')">
            <div class="icon-container">
              <md-icon class="icon">group</md-icon>
              <h3>users</h3>
            </div>
          </button>
          <button md-button routerLink="/companies" [disabled]="companiesRL.isActive" routerLinkActive="is-active" #companiesRL="routerLinkActive" *ngIf="linkAvailable('companies')">
            <div class="icon-container">
              <md-icon class="icon">group</md-icon>
              <h3>companies</h3>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="dropdown">
      <button md-button class="dropdown-btn">
        <div class="icon-container">
          <md-icon class="icon">assignment</md-icon>
          <h3>orders</h3>
        </div>
      </button>
      <div class="dropdown-content">
        <button md-button routerLink="/rfqs" [disabled]="rfqsRL.isActive" routerLinkActive="is-active" #rfqsRL="routerLinkActive" *ngIf="linkAvailable('rfqs')">
          <div class="icon-container">
            <md-icon class="icon">assignment</md-icon>
            <h3>rfqs</h3>
          </div>
        </button>
        <button md-button routerLink="/quotes" [disabled]="quotesRL.isActive" routerLinkActive="is-active" #quotesRL="routerLinkActive" *ngIf="linkAvailable('quotes')">
          <div class="icon-container">
            <md-icon class="icon">playlist_add_check</md-icon>
            <h3>quotes</h3>
          </div>
        </button>
      </div>
    </div>
    <div class="dropdown">
      <button md-button class="dropdown-btn">
        <div class="icon-container">
          <md-icon class="icon">view_module</md-icon>
          <h3>catalog</h3>
        </div>
      </button>
      <div class="dropdown-content">
        <button md-button routerLink="/brands" [disabled]="brandsRL.isActive" routerLinkActive="is-active" #brandsRL="routerLinkActive" *ngIf="linkAvailable('brands')">
          <div class="icon-container">
            <md-icon class="icon">favorite_border</md-icon>
            <h3>manufacturers</h3>
          </div>
        </button>
        <button md-button routerLink="/categories" [disabled]="categoriesRL.isActive" routerLinkActive="is-active" #categoriesRL="routerLinkActive" *ngIf="linkAvailable('categories')">
          <div class="icon-container">
            <md-icon class="icon">dashboard</md-icon>
            <h3>categories</h3>
          </div>
        </button>
        <button md-button routerLink="/products" [disabled]="productsRL.isActive" routerLinkActive="is-active" #productsRL="routerLinkActive" *ngIf="linkAvailable('products')">
          <div class="icon-container">
            <md-icon class="icon">label</md-icon>
            <h3>products</h3>
         </div>
        </button>
        <button md-button routerLink="/solutions" [disabled]="solutionsRL.isActive" routerLinkActive="is-active" #solutionsRL="routerLinkActive" *ngIf="linkAvailable('solutions')">
          <div class="icon-container">
            <md-icon class="icon">view_module</md-icon>
            <h3>solutions</h3>
          </div>
        </button>
        <button md-button routerLink="/parts" [disabled]="partsRL.isActive" routerLinkActive="is-active" #partsRL="routerLinkActive" *ngIf="linkAvailable('parts')">
          <div class="icon-container">
            <md-icon class="icon">view_module</md-icon>
            <h3>parts</h3>
          </div>
        </button>
      </div>
    </div>
    <div class="dropdown">
      <button md-button class="dropdown-btn">
        <div class="icon-container">
          <md-icon class="icon">folder</md-icon>
          <h3>other</h3>
        </div>
      </button>
      <div class="dropdown-content">
        <button md-button routerLink="/marketplace" [disabled]="marketplaceRL.isActive" routerLinkActive="is-active" #marketplaceRL="routerLinkActive" *ngIf="linkAvailable('marketplace')">
          <div class="icon-container">
            <md-icon class="icon">assignment</md-icon>
            <h3>marketplace</h3>
          </div>
        </button>
        <button md-button routerLink="/searches" [disabled]="searchesRL.isActive" routerLinkActive="is-active" #searchesRL="routerLinkActive" *ngIf="linkAvailable('searches')">
          <div class="icon-container">
            <md-icon class="icon">search</md-icon>
            <h3>searches</h3>
          </div>
        </button>
        <button md-button routerLink="/reports" [disabled]="reportsRL.isActive" routerLinkActive="is-active" #reportsRL="routerLinkActive" *ngIf="linkAvailable('reports')">
          <div class="icon-container">
            <md-icon class="icon">folder</md-icon>
            <h3>reports</h3>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="overlay" (click)="toggle()"></div>
