<div class="category-container">
  <md-grid-list cols="4" rowHeight="4:3" gutterSize="25px">
    <md-grid-tile class="category-grid" *ngFor="let category of categories.data">
      <div class="grid-content">
        <div class="category-image-wrap">
          <img [src]="category.attributes.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'">
        </div>
        <div class="category-details">
          <h3>{{ category.attributes.name }}</h3>
        </div>
      </div>
    </md-grid-tile>
  </md-grid-list>
</div>
