<div class="table-header" layout="row" hide show-gt-xs>
    <div layout="row" class="cell flex-1">
      <h3>offer#</h3>
    </div>
    <div layout="row" class="cell flex-2">
      <h3>part number</h3>
    </div>
    <div layout="row" class="cell flex-1">
      <h3>qty</h3>
    </div>
    <div class="cell flex-1">
        <h3>status</h3>
    </div>
    <div class="cell flex-2">
      <h3>ex-works</h3>
    </div>
    <div class="cell flex-3">
        <h3>user</h3>
    </div>
    <div class="cell flex-2"></div>
</div>
  
<md-list class="list">
    <ng-template ngFor let-offer [ngForOf]="offers.data">
      <div class="list-row" layout="row" [class.selected]="offer.attributes.selected">
        <div layout="row" class="cell flex-1">
          <span>{{ offer.id }}</span>
        </div>
        <div layout="row" class="cell flex-2 part-number">
          <span>{{ offer.relItem(offer.included, 'part') ? offer.relItem(offers.included, 'part').attributes.part_number : offer.attributes.custom_part }}</span>
        </div>
        <div layout="row" class="cell flex-1">
          <span>{{ offer.attributes.quantity }}</span>
        </div>
        <div layout="row" class="cell flex-1">
          <span>{{ offer.attributes.status }}</span>
        </div>
        <div layout="row" class="cell flex-2">
            <span>{{ offer.relItem(offers.included, 'ex_works').attributes.city }}, {{ offer.relItem(offers.included, 'ex_works').attributes.state }} {{ offer.relItem(offers.included, 'ex_works').attributes.country }}</span>
        </div>
        <div layout="row" class="cell flex-3">
            <span>{{ offer.relItem(offers.included, 'user').attributes.email }}</span>
            <div *ngIf="offer.relItem(offers.included, 'user').relCompany(this.offers.included, 'company_users')">Company: {{ offer.relItem(offers.included, 'user').relCompany(offers.included, 'company_users').attributes.name }}</div>
        </div>
        <div layout="row" class="cell flex-2">
            <div layout="row">
                <button md-button type="button" name="button" class="btn-action" [routerLink]="[offer.id]" (click)="markAsSelected(offer)">Edit</button>
                <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!offer.action('delete')" (click)="deleteWarningDialog(offer, 'Are you sure you want to archive this offer?')">
                  <md-icon >clear</md-icon>
                </button>
            </div>
        </div>
    </div>
    <md-divider></md-divider>
    </ng-template>
</md-list>