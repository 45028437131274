<div class="brand-container">
  <md-grid-list cols="4" rowHeight="4:3" gutterSize="25px">
    <md-grid-tile class="brand-grid" *ngFor="let brand of brands.data">
      <div class="grid-content">
        <div class="brand-image-wrap">
          <img [src]="brand.attributes.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'">
        </div>
        <div class="brand-details">
          <h3>{{ brand.attributes.name }}</h3>
        </div>
      </div>
    </md-grid-tile>
  </md-grid-list>
</div>
