<div class="page-container" layout="column" layout-align="center center">
  <div class="text-container">
    <div class="main-text">
      <h1>500</h1>
      <h2>Something Took Our Services Down</h2>
      <p>We are working on getting it back online as soon as possible</p>
      <button md-button routerLink="/"><h3>back to safety!</h3></button>
    </div>
    <pre>{{ errors | json }}</pre>
  </div>
  <div class="city-container">
    <img src="/assets/img/oops/city.png" class="city" />
    <div class="city-background"></div>
  </div>
  <div class="city-shadow">
    <img src="/assets/img/oops/city.png" class="city" />
    <div class="city-background"></div>
  </div>
  <img src="/assets/img/oops/monster.png" class="monster" />
</div>
