<div md-dialog class="dialog">
    <div class="header">
      <h1 md-dialog-title>{{ title }}</h1>
    </div>
    <div md-dialog-content class="content">
      <md-list class="list" *ngIf="content">
        <ng-template ngFor let-item [ngForOf]="content.data">
          <div class="list-row" layout="row" [class.selected]="item.attributes.selected">
            <div layout="row" class="cell flex">
              <div class="item-image-wrap" *ngIf="item.attributes">
                <img [src]="item.attributes.image_url" onerror="this.onerror=null; this.src='../../../assets/img/default-image.png'">
              </div>
            </div>
            <div layout="row" class="cell flex-3 name">
              <span class="name">{{ item.attributes.category_name ? item.attributes.category_name + ' - ' + item.attributes.name : item.attributes.name }}</span>
            </div>
            <div layout="row" class="cell flex">
              <div layout="row">
                <button md-button type="button" name="button" class="btn-action" (click)="dialogRef.close(item)">Select</button>
              </div>
            </div>
          </div>
          <md-divider></md-divider>
        </ng-template>
      </md-list>
    </div>
    <div md-dialog-actions align="end">
      <button md-button (click)="dialogRef.close()">CANCEL</button>
    </div>
</div>
