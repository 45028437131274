<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="table-header" layout="row" hide show-gt-xs>
    <div class="cell flex-3">
      <h3>Part #</h3>
    </div>
    <div class="cell flex-2">
      <h3>Qty</h3>
    </div>
    <div class="cell flex-2">
      <h3>Price</h3>
    </div>
    <div class="cell flex-2">
      <h3>Lead Time</h3>
    </div>
    <div class="cell flex-2">
      <h3>Total Price</h3>
    </div>
  </div>

  <md-list class="list" layout="column">
    <div *ngFor="let rfqPart of (quote.relItem(quote.included, 'rfq').relItems(quote.included, 'rfq_parts') | deletedAt)">
      <div layout="row" class="event-row clickable-row">
        <div class="cell flex-3">
          <p *ngIf="rfqPart.relItem(quote.included, 'part')">{{ rfqPart.relItem(quote.included, 'part').attributes.part_number }}</p>
          <p *ngIf="!rfqPart.relItem(quote.included, 'part')">{{ rfqPart.attributes.custom_name }}</p>
        </div>
        <div class="cell flex-2">
          <p>{{ rfqPart.attributes.quantity }}</p>
        </div>
        <div class="cell flex-2">
          <form>
            <input [disabled]="isDraft(quote)" class="cell-input" [(ngModel)]="rfqPart.attributes.price" [ngModelOptions]="{standalone: true}" type="number" (blur)="setPrice($event, rfqPart)" step="1">
          </form>
        </div>
        <div class="cell flex-2">
          <form>
            <input [disabled]="isDraft(quote)" class="cell-input" [(ngModel)]="rfqPart.attributes.lead_time" [ngModelOptions]="{standalone: true}" type="text" (blur)="setPrice($event, rfqPart)" step="1">
          </form>
        </div>
        <div class="cell flex-2">
          <p>{{ calculateTotalPrice(rfqPart.attributes.quantity, rfqPart.attributes.price) }}</p>
        </div>
      </div>
      <md-divider></md-divider>
    </div>
  </md-list>

  <div class="content">
    <div *ngIf="quote.relItem(this.quote.included, 'rfq').attributes.purchase_order_number">
      <h3>Purchase Order Number</h3>
      <span>{{ quote.relItem(this.quote.included, 'rfq').attributes.purchase_order_number }}</span>
    </div>

    <div *ngFor="let qt of quoteTimestamps">
      <div *ngIf="quote.attributes[qt.name]">
        <h3>{{ qt.label }}</h3>
        <span>{{ quote.attributes[qt.name] | date : 'short' }}</span>
      </div>
    </div>

    <div *ngIf="quote.attributes.order_message">
      <h3>Order Message</h3>
      <span>{{ quote.attributes.order_message }}</span>
    </div>

    <div *ngIf="action.field('shipped_at')">
      <h3>Ship date</h3>
      <new-md2-datepicker [disabled]="isDraft(quote)" openOnFocus="true" (change)="onDatePickerChange($event, 'shipped_at')" placeholder="{{ form.value['shipped_at'] | formatDate }}"></new-md2-datepicker>
    </div>

    <div *ngIf="action.field('shipment_expected_at')">
      <h3>Delivery date</h3>
      <app-text-input [disabled]="isDraft(quote)" [action]="action" field="shipment_expected_at" (change)="onInputFieldChange($event, 'shipment_expected_at')" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('estimated_delivery')">
      <h3>Delivery date</h3>
      <new-md2-datepicker [disabled]="isDraft(quote)" openOnFocus="true" (change)="onDatePickerChange($event, 'estimated_delivery')" placeholder="{{ form.value['estimated_delivery'] | formatDate }}"></new-md2-datepicker>
    </div>

    <div *ngIf="action.field('carrier')">
      <h3>Ship via</h3>
      <app-text-input [disabled]="isDraft(quote)" [action]="action" field="carrier" (change)="onInputFieldChange($event, 'carrier')" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('tracking_number')">
      <h3>Tracking</h3>
      <app-text-input [disabled]="isDraft(quote)" [action]="action" field="tracking_number" (change)="onInputFieldChange($event, 'tracking_number')" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('shipping_cost')">
      <h3>Shipping Cost</h3>
      <app-text-input [disabled]="isDraft(quote)" [action]="action" field="shipping_cost" type="number" (change)="onInputFieldChange($event, 'shipping_cost')" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('due_date')">
      <h3>Due date</h3>
      <new-md2-datepicker [disabled]="isDraft(quote)" openOnFocus="true" (change)="onDatePickerChange($event, 'due_date')" placeholder="{{ form.value['due_date'] | formatDate }}"></new-md2-datepicker>
    </div>

    <div *ngIf="action.field('paid')" layout="column">
      <h3>Paid</h3>
      <md-checkbox [disabled]="isDraft(quote)" [checked]="this.quote.attributes.paid" (change)="onCheckBoxChange($event, 'paid')">{{ this.quote.attributes.paid ? "Paid" : "The invoice has not been paid yet" }}</md-checkbox>
    </div>

    <div *ngIf="action.field('action')" layout="column">
      <h3>Action</h3>
      <md-checkbox [checked]="this.quote.attributes.action" (change)="onCheckBoxChange($event, 'action')">{{ this.quote.attributes.action ? "Required" : "Completed" }}</md-checkbox>
    </div>

    <div class="purchase-order">
      <h3 *ngIf="quote.attributes.ordered_at">Purchase order:</h3>
      <p *ngIf="quote.attributes.ordered_at">{{ setRfqBooleans(this.quote.attributes.is_purchase_order) }}</p>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>Update</span>
        </div>
      </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>

    <div *ngIf="closeWonAction" class="button-container" layout="row">
      <button md-button type="button" class="btn-cancel" (click)="markAsWon()">
        <div class="button-content">
          <md-icon>insert_invitation</md-icon>
          <span>Mark As Closed One</span>
        </div>
      </button>
    </div>
  </div>
</form>
