<div class="table-header" layout="row" hide show-gt-xs>
    <div layout="row" class="cell flex-2 name-cell">
      <h3>name</h3>
    </div>
    <div layout="row" class="cell flex-2">
      <h3>description</h3>
    </div>
    <div class="cell flex-1 button-cell">
    </div>
    <button md-button type="button" class="btn-add" (click)="newSolution()" *ngIf="createAction && !hidden">
      <md-icon>add</md-icon>
    </button>
</div>
<md-list class="list">
    <div class="list-group" dnd-sortable-container [sortableData]="solutions.data">
        <div *ngFor="let solution of solutions.data; let i = index" dnd-sortable (onDropSuccess)="dragAndDrop.dropElement(solutions.action('order'), solutions.data)" [sortableIndex]="i">
            <div class="list-row" layout="row" [class.selected]="solution.attributes.selected">
                <div layout="row" class="cell flex-2 name-cell">
                    <span>{{ solution.attributes.name }}</span>
                </div>
                <div layout="row" class="cell flex-2 fields-cell">
                    <span>{{ solution.attributes.description }}</span>
                </div>
                <div layout="row" class="cell flex-1 button-cell">
                    <div layout="row">
                        <button *ngIf="!hidden" md-button type="button" name="button" class="btn-action" [routerLink]="[solution.id, 'summary']" (click)="markAsSelected(solution)">Edit</button>
                        <button *ngIf="!hidden" md-button class="btn-action btn-hide" type="button" name="button" [disabled]="!solution.action('hide')" (click)="hideWarningDialog(solution, 'Are you sure you want to hide this solution?')">hide</button>
                        <button md-button class="btn-action btn-delete" type="button" name="button" [disabled]="!solution.action('delete')" (click)="deleteWarningDialog(solution, 'Are you sure you want to delete this solution?')">delete</button>
                        <button *ngIf="hidden" md-button class="btn-action btn-hide" type="button" name="button" (click)="unhideSolution(solution)">unhide</button>               
                    </div>
                </div>
            </div>
        <md-divider></md-divider>
        </div>
    </div>
</md-list>