<nav>
  <ul layout="row">
    <li
      *ngFor="let tab of tabs"
      #rla="routerLinkActive"
      routerLinkActive="is-selected"
      [routerLinkActiveOptions]="{exact: true}"
      [class.active]="rla.isActive"
      [class.disabled]="isDisabled(tab)"
      class="tab"
      primary-color>
      <a [routerLink]="tab.link">
        <h2>{{ tab.label }}</h2>
      </a>
    </li>
  </ul>
</nav>
