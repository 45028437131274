<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>{{company ? 'Edit ' + company.attributes.name : 'Create New Company' }}</h2>
    </div>
    <md-divider></md-divider>
  </md-list>
  <div class="content">
    <div *ngIf="action.field('name')">
      <h3>Name</h3>
      <app-text-input [action]="action" field="name" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('industry')">
      <h3>Industry</h3>
      <app-text-input [action]="action" field="industry" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('size')">
      <h3>Size</h3>
      <app-text-input [action]="action" field="size" placeholder=""></app-text-input>
    </div>
    <div *ngIf="action.field('company_type')">
      <h3>Company Type</h3>
      <app-text-input [action]="action" field="company_type" placeholder=""></app-text-input>
    </div>

    <div *ngIf="action.field('roles_payload')" layout="column">
      <h3>Role</h3>
      <md-checkbox [checked]="hasRole(option.value)" (change)="toggleRole(option.value)" *ngFor="let option of action.field('roles_payload').options">{{ option.label }}</md-checkbox>
    </div>

    <br>

    <div *ngIf="action.field('image_url')">
      <app-s3-uploader [action]="action" field="image_url" [showImage]="true" config="company_image" defaultStatus="Drag and Drop Image Here" failStatus="Invalid File - Please Upload an Image" successStatus="File Uploaded!" [allowedExtensions]="['.gif', '.jpg', '.png', '.jpeg', '.svg']"></app-s3-uploader>
    </div>

    <div class="button-container" layout="row">
      <button md-button type="submit" class="btn-action" [disabled]="action.submitting || !form.valid">
          <div class="button-content">
            <md-icon>insert_invitation</md-icon>
            <span>{{ company ? 'Update' : 'Create' }}</span>
          </div>
        </button>
      <button md-button type="button" class="btn-cancel" (click)="close()" queryParamsHandling="preserve">
        <div class="button-content">
          <md-icon>clear</md-icon>
          <span>Cancel</span>
        </div>
      </button>
    </div>
  </div>
</form>
<div *ngIf="!form">
  <md-list>
    <div class="title" layout="row" layout-align="start center">
      <md-icon class="clickable mobile" (click)="close()">arrow_back</md-icon>
      <h2>Oops! Something went wrong.</h2>
    </div>
  </md-list>
</div>
