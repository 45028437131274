<div class="reset-password-wrap" [ngClass]="routeState">
  <div class="reset-password-form">
    <form flex layout="column" layout-align="center" [formGroup]="form" (ngSubmit)="submit()">
      <h1>Forgot Your Password?</h1>
      <p class="text-md">It's okay, it happens.</p>
      <p class="text-md">Enter you email below and we will help you reset it.</p>
      <app-text-input [action]="action" field="email" floatPlaceholder></app-text-input>
      <button md-button type="submit" [disabled]="!form.valid" class="btn-submit">Submit</button>
      <a routerLink="/" (click)="form.reset()" class="text-sm form-link">Back</a>
    </form>
  </div>
  <div class="reset-password-confirm">
    <div flex layout="column" layout-align="center">
      <h1>Email Sent!</h1>
      <p class="text-md">Check your email to find a password reset link.</p>
    </div>
    <a routerLink="/" (click)="resetState()" class="text-sm text-center">Back</a>
  </div>
</div>
