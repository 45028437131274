<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell flex-5">
    <h3>Term</h3>
  </div>
  <div layout="row" class="cell flex-5">
    <h3>Date</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>User</h3>
  </div>
</div>

<md-list class="list">
  <ng-template ngFor let-search [ngForOf]="searches.data">
    <div class="list-row" layout="row" [class.selected]="search.attributes.selected">
      <div layout="row" class="cell flex-5">
        <span>{{ search.attributes.term }}</span>
      </div>
      <div layout="row" class="cell flex-5">
        <small>{{ search.attributes.created_at | date : 'short' }}</small>
      </div>
      <div layout="row" class="cell flex-2">
        <a *ngIf="search.attributes.user_id && search.attributes.user_id > 0" [routerLink]="['../users', search.attributes.user_id]">info</a>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>
