<!-- <p class="text-sm sub-title" *ngIf="!pending">Users who have current access to the system</p> -->

<button md-button type="button" class="add-button" primary-bg-color routerLink="create" *ngIf="createAction">
  <md-icon>add</md-icon>
</button>
<div class="table-header" layout="row" hide show-gt-xs>
  <div layout="row" class="cell user-cell flex-2">
    <app-avatar></app-avatar>
    <h3>name</h3>
  </div>
  <div layout="row" class="cell flex-2">
    <h3>company</h3>
  </div>
  <div class="cell flex-2">
    <h3>role</h3>
  </div>
  <div class="cell email-cell flex-3">
    <h3>email</h3>
  </div>
  <div class="cell flex-3">
  </div>
</div>

<!-- DESKTOP LIST -->
<md-list class="list" hide show-gt-xs>
  <ng-template ngFor let-user [ngForOf]="users.data">
    <div class="list-row" layout="row" [class.selected]="user.attributes.selected">
      <div layout="row" class="user-cell cell flex-2">
        <app-avatar [src]="user.attributes.profile_image_url"></app-avatar>
        <span class="username">{{ user.attributes.first_name }} {{ user.attributes.last_name }} <span *ngIf="user.attributes.deleted_at">[DELETED]</span></span>
      </div>
      <div class="cell flex-2">
        <div layout="column">
          <span *ngIf="user.relCompany(users.included, 'company_users')">{{ user.relCompany(users.included, 'company_users').attributes.name }}</span>
        </div>
      </div>
      <div class="cell flex-2">
        <div layout="column">
          <span class="name">{{ user.attributes.roles }}</span>
        </div>
      </div>
      <div class="cell email-cell flex-3">
        <span class="name">{{ user.attributes.email }}</span>
      </div>
      <div class="cell button-cell flex-3" layout="row" *ngIf="!user.attributes.pending">
        <button md-button type="button" name="button" class="btn-action" [routerLink]="[user.id]" (click)="markAsSelected(user)">Edit</button>
      </div>
    </div>
    <md-divider></md-divider>
  </ng-template>
</md-list>

<!-- MOBILE LIST -->
<div class="list-container">
  <div class="list" hide-gt-xs>
    <ng-template ngFor let-user [ngForOf]="users.data">
      <md-card class="list-row" layout="row">
        <div class="details flex-3" layout="column" layout-align="center">
          <app-avatar [src]="user.attributes.profile_image_url"></app-avatar>
          <div class="info clickable" [routerLink]="[user.id]" layout="column">
            <h3>{{ user.attributes.first_name }} {{ user.attributes.last_name }} <span *ngIf="user.attributes.deleted_at">[DELETED]</span></h3>
            <span *ngIf="user.relCompany(users.included, 'company_users')" class="text-sm">
              <span>{{ user.relCompany(users.included, 'company_users').attributes.name }}</span>
            </span>
            <span class="text-sm">
              {{ user.attributes.roles }}
            </span>
            <span class="text-sm">
              {{ user.attributes.email }}
            </span>
          </div>
        </div>
        <div class="button-container" layout="column" layout-align="center" *ngIf="!user.attributes.pending">
          <button md-button type="button" name="button" class="btn-action" [routerLink]="[user.id]" (click)="markAsSelected(user)">Edit</button>
        </div>
        <div class="button-container" layout="column" layout-align="center" *ngIf="user.attributes.pending">
          <button md-button type="button" name="button" class="btn-action" (click)="resendInvite(user)">Resend Invite</button>
        </div>
      </md-card>
    </ng-template>
  </div>
</div>
